import { ReactSVG } from "react-svg";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import { useMediaQuery } from "@mui/material";
import { Box, Drawer } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { HoverEffect } from "../style";
import Scrollbar from "../Scrollbar";
import NavSection from "../NavSection";
import User from "../../icons/User";
import MyContent from "../../icons/MyContent";
import DashboardIcon from "../../icons/DashboardIcon";

const admin_sections = [
  {
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <img src="/static/Logo.png" alt="logo" width="100%" />,
      },
      {
        title: "Add",
        icon: (
          <ReactSVG
            src="/static/svgs/plus.svg"
            style={{ marginTop: "11px", marginLeft: "3px" }}
          />
        ),
      },
      {
        title: "Overview",
        path: "/dashboard",
        icon: (
          <DashboardIcon
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },

      {
        title: "My Content",
        path: "/dashboard/mycontent",
        icon: (
          <MyContent
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },

      {
        title: "User",
        path: "/dashboard/user",
        icon: (
          <User
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },
    ],
  },
];

const lectturer_sections = [
  {
    items: [
      {
        title: "Dashboard",
        path: "/dashboard",
        icon: <img src="/static/Logo.png" alt="logo" width="100%" />,
      },

      {
        title: "Add",
        icon: (
          <ReactSVG
            src="/static/svgs/plus.svg"
            style={{ marginTop: "11px", marginLeft: "3px" }}
          />
        ),
      },
      {
        title: "Overview",
        path: "/dashboard",
        icon: (
          <DashboardIcon
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },

      {
        title: "My Content",
        path: "/dashboard/mycontent",
        icon: (
          <MyContent
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },
      {
        title: "User",
        path: "/dashboard/user",
        icon: (
          <User
            fontSize="large"
            style={{ fontSize: "30px", marginTop: "20px", marginLeft: "7px" }}
          />
        ),
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints?.up("lg"));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <HoverEffect>
          {JSON.parse(Cookies.get("user")).is_admin ? (
            <Box sx={{ p: 0 }}>
              {admin_sections.map((section, idx) => (
                <NavSection
                  key={idx}
                  pathname={location.pathname}
                  {...section}
                />
              ))}
            </Box>
          ) : (
            <Box sx={{ p: 0 }}>
              {lectturer_sections.map((section, idx) => (
                <NavSection
                  key={idx}
                  pathname={location.pathname}
                  {...section}
                />
              ))}
            </Box>
          )}
        </HoverEffect>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 0px) !important",
            top: "0px !Important",
            width: 70,
            border: "none",
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 70,
          border: "none",
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
