import axios, {
  Axios,
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import Cookies from "js-cookie";
import { toastError } from "@8chili/uikit";
import { GetOptions, QueryParams } from "./type";
import routesName from "../../../../apps/admin-panel/src/routes/routesName";

const TIMEOUT_LIMIT_60_SEC = 60 * 1000;

const prepareFormData = (data: any) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (value) {
      formData.append(key, value as any);
    }
  });

  return formData;
};

const axiosDefaultConfig: AxiosRequestConfig = {
  baseURL: import.meta.env.PROD
    ? undefined
    : import.meta.env.VITE_APP_BASE_URL || undefined,
  timeout: TIMEOUT_LIMIT_60_SEC,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: localStorage.getItem("auth_token"),
  },
};

const unauthorizableEndpoints = [
  `${window.location.origin}/api/user/login`,
  `${window.location.origin}/api/user/signup`,
  `${window.location.origin}/api/user/pin-login`,
  `${window.location.origin}/api/user/set_password`,
  `${window.location.origin}/api/user/register_app_user`,
  `${window.location.origin}/api/user/request_password_reset`,
];

const removeQueryString = (url: string) => {
  const urlIndex = url.indexOf("?");
  return url.slice(0, urlIndex === -1 ? undefined : urlIndex);
};

const shouldClearAuthToken = (error: any) => {
  return (
    error.response.status === 401 &&
    !unauthorizableEndpoints.includes(error.request.responseURL) &&
    window.location.pathname !== "/verify-code" &&
    !error.request.responseURL.includes("/api/enrollment")
  );
};

const shouldAllowWithoutAuthToken = (apiURL: string) => {
  return unauthorizableEndpoints.includes(removeQueryString(apiURL));
};

// Error handler for APIs
const apiErrorHandler = (error: AxiosError | undefined | null) => {
  if (error?.response?.status === 401) {
    if (!(error?.response?.data as any).msg) {
      toastError(
        "Unauthorized Access",
        "Your session has either expired or not available anymore"
      );
    } else {
      toastError(
        typeof (error.response.data as any).status === "string"
          ? (error.response.data as any).status
          : "Unauthorized Access",
        (error.response.data as any).msg
      );
    }

    if (shouldClearAuthToken(error)) {
      localStorage.removeItem("auth_token");
      Cookies.remove("user");

      if (
        !shouldAllowWithoutAuthToken(
          (window.location.origin || "") + error.response.config.url ||
            window.location.href
        )
      ) {
        window.location.pathname = `${routesName.login.route}`;
      }
    }
  } else if (error) {
    if (error.code === AxiosError.ERR_BAD_REQUEST && error?.response?.data) {
      const [key, value] = Object.entries(error?.response?.data)?.[0];
      toastError(error.message, `${key} - ${value}`);
      return;
    }

    toastError(
      error.message,
      "Something went wrong on our side, please try again after sometime."
    );
  } else {
    toastError(
      "Something went wrong",
      "Hint-CMS have encountered an error, please contact us if the issue persist."
    );
  }

  // This is just for debugging purpose.
  console.error(error);
};

const cleanPostData = (data) => {
  if (data instanceof FormData) {
    const entries = data.entries();

    for (const entry of entries) {
      if (!entry[1]) data.delete(entry[0]);
    }
  } else if (
    typeof data === "object" &&
    data !== null &&
    !Array.isArray(data)
  ) {
    const keys = Object.keys(data);
    const tempData = {};

    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]]) {
        tempData[keys[i]] = data[keys[i]];
      }
    }

    data = tempData;
  }

  return data;
};

const getKeysWithValues = (obj: any = {}) =>
  Object.entries(obj).reduce(
    (accumulator, [key, value]) =>
      Boolean(value) ? (accumulator[key] = value) && accumulator : accumulator,
    {} as any
  );

class ApiService extends Axios {
  private axiosInstance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    super(config);
    this.axiosInstance = axios.create(config);

    // REQUEST INTERCEPTOR
    this.axiosInstance.interceptors.request.use((req) => {
      if (req.url?.includes("s3.amazonaws.com")) {
        delete req.headers?.common?.Authorization;
        delete req.headers?.Authorization;
      }

      if (
        !localStorage.getItem("auth_token") &&
        !shouldAllowWithoutAuthToken(window.location.origin + req.url)
      ) {
        window.location.pathname = "/login";
        return Promise.reject("Please Login");
      } else {
        req.headers.Authorization = `Bearer ${localStorage.getItem("auth_token")}`;
      }

      return req;
    });

    // RESPONSE INTERCEPTOR
    this.axiosInstance.interceptors.response.use(
      (res) => {
        return Promise.resolve(res);
      },
      (error) => {
        if (!error.response) {
          return Promise.reject(error);
        }

        apiErrorHandler(error);
        return error
      }
    );
  }

  generateQueryString(queryParams: QueryParams) {
    const entries = Object.entries(queryParams);

    if (entries.length === 0) return "";

    return entries.reduce((value: string, currentValue, currenIndex) => {
      return currenIndex === 0
        ? value + `${currentValue[0]}=${currentValue[1]}`
        : value + `&${currentValue[0]}=${currentValue[1]}`;
    }, "?");
  }

  async getData(url: string, options?: GetOptions) {
    const { requestParams = {}, params, ...otherOptions } = options || {};

    const queryString = this.generateQueryString(requestParams);
    const requestURL = url + queryString;

    try {
      const res = await this.axiosInstance.get(requestURL, {
        ...otherOptions,
        params: getKeysWithValues(params),
      });
      return res;
    } catch (err: any) {
      apiErrorHandler(err);
      return err
    }
  }

  async postData(url: string, data: any, options?: AxiosRequestConfig) {
    try {
      const cleanedData = cleanPostData(data);
      const res = await this.axiosInstance.post(url, cleanedData, options);
      return res;
    } catch (err: any) {
      apiErrorHandler(err);
      return err
    }
  }

  async postForm(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const preparedData =
        data instanceof FormData ? data : prepareFormData(data);

      return await this.postData(url, preparedData, {
        ...config,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err: any) {
      apiErrorHandler(err);
      return err
    }
  }

  /**
   * Video upload requests to s3 are PUT request.
   */
  async s3Upload(uploadURL: string, file: any, config: AxiosRequestConfig) {
    try {
      // default values passed in axiosInstance is causing some issues. hence axios
      return await axios.put(uploadURL, file, {
        ...config,
        timeout: TIMEOUT_LIMIT_60_SEC * 1000,
      });
    } catch (err: any) {
      apiErrorHandler(err);
      return err
    }
  }
}

export default new ApiService(axiosDefaultConfig);
export * from "./type";
